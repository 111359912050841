import React from "react";
import "./style.css";

export default ({ teaser, phone, email, company, address, city, state, zip }) => (
  <React.Fragment>
    <div className="company-info-wrapper">
      <p className="company-info-teaser">
        { teaser }
      </p>
      <p className="company-info-phone">
        P &nbsp;{ phone }
      </p>
      <p className="company-info-email">
        E &nbsp;&nbsp;{ email }
      </p>
      <p className="company-info-name">
        { company }
      </p>
      <p className="company-info-street">
        { address }
      </p>
      <div className="company-location-wrapper">
        <p className="company-info-city">
          { city }
        </p>
        <p className="company-info-state">
          { state }
        </p>
        <p className="company-info-zip">
          { zip }
        </p>
      </div>
    </div>
  </React.Fragment>
)