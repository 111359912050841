import React from "react";
import "./style.css";
import HubspotForm from "react-hubspot-form";

export default ({ title, teaser, portalId, formId }) => (
  <React.Fragment>
    <div className="contact-section-wrapper">
      <h1 className="contact-section-title">
        { title }
      </h1>
      <h3 className="contact-section-teaser">
        { teaser }
      </h3>
      <HubspotForm
        portalId={ portalId }
        formId={ formId }
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
      />
    </div>
  </React.Fragment>
)