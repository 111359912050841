import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import SimpleHero from "../components/SimpleHero";
import Contact from "../components/Contact";
import CompanyInfo from "../components/CompanyInfo";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

class Support extends Component {
   render() {

    const {
      data: { seo, hero, contact, company },
    } = this.props

    const seoData = seo.primary;

    const heroData = hero.data;

    const contactData = contact.primary.cta.document.data;

    const companyData = company.primary.support_company_info.document.data;

    let compCity;
    if (company.primary.support_company_info.document.data.company_city.text === null) {
      compCity = "";
    } else {
      compCity = company.primary.support_company_info.document.data.company_city.text + ",";
    }

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container support-page">
          <NavBar />
          <SimpleHero
            title={ heroData.support_page_title.text 
            }
            teaser={ heroData.support_page_teaser.text }
            />
          <div className="contact-contact-wrapper">
            <Contact
              title={   contactData.contact_field_title.text }
              teaser={  contactData.contact_field_teaser.text }
              portalId={  contactData.hubspot_portal_id.text }
              formId={  contactData.hubspot_form_id.text }
              />
            <CompanyInfo
              teaser={ companyData.company_teaser.text }
              phone={ companyData.company_phone.text }
              email={ companyData.company_email.text }
              company={ companyData.company_name.text }
              address={ companyData.company_address.text }
              city={ compCity }
              state={ companyData.company_state }
              zip={ companyData.company_zip }
              />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
   }
}

export default Support

Support.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      primary: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        seo_description: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      data: PropTypes.shape({
        support_page_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        support_page_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      }),
    }),
    contact: PropTypes.shape({
      primary: PropTypes.shape({
        cta: PropTypes.shape({
          document: PropTypes.array.isRequired,
        }),
      }),
    }),
    company: PropTypes.shape({
      primary: PropTypes.shape({
        support_company_info: PropTypes.shape({
          document: PropTypes.array.isRequired,
        }),
      }),
    }),
  }),
}

export const supportQuery = graphql`
  query supportPage {

    seo: prismicSupportPageBody1SeoTags {
      primary {
        seo_title
        seo_description
      }
    }

    hero: prismicSupportPage {
      data {
        support_page_title {
          text
        }
        support_page_teaser {
          text
        }
      }
    }

    contact: prismicSupportPageBodyCta {
      primary {
        cta {
          document {
            ... on PrismicCta {
              data {
                contact_field_title {
                  text
                }
                contact_field_teaser {
                  text
                }
                hubspot_portal_id {
                  text
                }
                hubspot_form_id {
                  text
                }
              }
            }
          }
        }
      }
    }

    company: prismicSupportPageBodyCompanyInfo {
      primary {
        support_company_info {
          document {
            ... on PrismicCompanyInfo {
              data {
                company_teaser {
                  text
                }
                company_phone {
                  text
                }
                company_email {
                  text
                }
                company_name {
                  text
                }
                company_address {
                  text
                }
                company_city {
                  text
                }
                company_state
                company_zip
              }
            }
          }
        }
      }
    }
    
  }
`