import React from "react";
import "./style.css";

export default ({ title, teaser }) => (
  <React.Fragment>
    <div className="simple-hero-wrapper">
      <h1 className="simple-hero-title text-med">
        { title }
      </h1>
      <p className="simple-hero-teaser text-reg">
        { teaser }
      </p>
    </div>
  </React.Fragment>
)